import React from "react";
import styled, { css } from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import respond from "../styles/abstracts/mediaqueries";

const StyledAboutSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 6rem;
  padding: 4rem 0;

  ${respond(
    "tab-port",
    css`
      grid-template-columns: 1fr;
      row-gap: 3rem;
      column-gap: 0;
      padding: 2rem 0;
    `
  )}

  .text-container {
    grid-column: ${({ alternativeSection }) => (alternativeSection ? "1 / 2" : "2 / 3")};
    grid-row: 1 / 2;
    padding: 2rem 0;

    ${respond(
      "tab-port",
      css`
        padding: 0 !important;
        grid-row: 2 / 3;
      `
    )}

    h3 {
      font-size: var(--body-font-size);
      font-weight: 700;
    }
  }

  .section-image {
    grid-column: ${({ alternativeSection }) => (alternativeSection ? "2 / 3" : "1 / 2")};
    grid-row: 1 / 2;

    ${respond(
      "tab-port",
      css`
        grid-row: 1 / 2;
        grid-column: 1 / -1;
      `
    )}
  }

  .text {
    white-space: pre-line;
  }
`;

const AboutSection = ({ image, text, title, alternativeSection }) => {
  return (
    <StyledAboutSection alternativeSection={alternativeSection}>
      <div className="text-container">
        <h3>{title}</h3>
        <div className="text">{text}</div>
      </div>
      <GatsbyImage className="section-image" image={getImage(image.localFile)} alt={image.alternativeText} />
    </StyledAboutSection>
  );
};

export default AboutSection;
