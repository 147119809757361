import React from "react";
import styled, { css } from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import respond from "../../styles/abstracts/mediaqueries";

import AboutSection from "../../components/AboutSection";

const StyledAboutSections = styled.main`
  .about-top-section {
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;

      ${respond(
        "phone-port",
        css`
          grid-template-columns: 1fr;
          width: 90%;
          margin: 0 auto;
        `
      )}
    }

    &__left {
      position: relative;
    }

    &__text-container {
      padding: 5rem;

      ${respond(
        "phone-port",
        css`
          padding: 0;
          width: 90%;
        `
      )}
    }

    &__title {
      color: var(--color-primary);
      font-size: 7rem;
      font-weight: 400;

      ${respond(
        "phone-port",
        css`
          font-size: 6rem;
        `
      )}
    }

    &__quote {
      font-family: var(--body-font);
      font-style: italic;
      font-weight: 400;
      font-size: 3rem;

      ${respond(
        "phone-port",
        css`
          font-size: 2.5rem;
        `
      )}
    }

    &__main-image {
    }

    &__light-image {
      position: absolute;
      top: 0;
      left: 0;
      //height: 80%;
      z-index: -1;

      ${respond(
        "tab-land",
        css`
          width: 60%;
          margin-left: 2rem;
        `
      )}
    }
  }

  hr {
    border-top: 15px solid var(--color-secondary);
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin-top: 6rem;
  }

  .secondary-sections {
    ${respond(
      "phone-port",
      css`
        width: 90%;
        margin: 0 auto;
      `
    )}
  }
`;

const AboutSections = ({ data }) => {
  //console.log(data);

  return (
    <StyledAboutSections>
      <div className="container">
        {data.map(({ id, title, quote, lightImage, mainImage, aboutInnerSection }, i) => {
          const isLastItem = i === data.length - 1;
          return (
            <section key={id} className="about-top-section">
              <div className="about-top-section__container">
                <div className="about-top-section__left">
                  <div className="about-top-section__text-container">
                    <h2 className="about-top-section__title">{title}</h2>
                    <blockquote className="about-top-section__quote">{quote}</blockquote>
                  </div>
                  <GatsbyImage
                    className="about-top-section__light-image"
                    image={getImage(lightImage.localFile)}
                    alt={lightImage.alternativeText}
                  />
                </div>
                <GatsbyImage
                  className="about-top-section__main-image"
                  image={getImage(mainImage.localFile)}
                  alt={mainImage.alternativeText}
                />
              </div>
              <div className="secondary-sections">
                {aboutInnerSection.map((sectionData, i) => {
                  const alternativeSection = i % 2 !== 0;
                  return <AboutSection {...sectionData} key={sectionData.id} alternativeSection={alternativeSection} />;
                })}
              </div>
              {!isLastItem && <hr />}
            </section>
          );
        })}
      </div>
    </StyledAboutSections>
  );
};

export default AboutSections;
