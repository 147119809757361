import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout/Layout";

import Seo from "../components/Seo";
import InnerHero from "../components/InnerHero";
import AboutSections from "../sections/about/AboutSections";

const AboutPage = ({
  data: {
    strapiNicoleWebsite: { aboutSection: aboutSections },
  },
}) => {
  return (
    <main>
      <Seo title={"Nicole Tabs | About"} description={""} language="en" />
      <InnerHero />
      <AboutSections data={aboutSections} />
      <Layout innerPage></Layout>
    </main>
  );
};

export const query = graphql`
  query AboutSections {
    strapiNicoleWebsite {
      aboutSection {
        id
        title
        quote
        mainImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        lightImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        aboutInnerSection {
          id
          text
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          aboutSectionTitle
        }
      }
    }
  }
`;

export default AboutPage;
